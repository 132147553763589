
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import toasts from '@/utils/toasts';
import { Actions, Getters } from '@/store/enums/VerifyAccountEnums';

export default defineComponent({
  name: 'resend-verify-account-notice',
  data: () => ({
    sending: false,
  }),
  computed: {
    ...mapGetters({
      errors: Getters.GET_ACTION_ERROR,
    }),
  },
  mounted() {
    this.requestNewToken();
  },
  methods: {
    ...mapActions({
      requestNewToken: Actions.REQUEST_NEW_TOKEN,
    }),
    handleClick() {
      if (this.sending) return;

      this.sending = true;

      this.requestNewToken()
        .then(() => {
          this.sending = false;
          toasts.success(
            'New verification link sent. Please check your email.'
          );
        })
        .catch(() => {
          this.sending = false;
          const { errors, messages } = this.errors;
          toasts.error(errors, messages);
        });
    },
  },
});
