
import { defineComponent } from 'vue';
import VerifyAccountNotice from '@/components/sections/verify-account/VerifyAccountNotice.vue';

export default defineComponent({
  name: 'verify-account-notice',
  components: {
    VerifyAccountNotice,
  },
});
